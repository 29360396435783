import Intro from "../../components/Intro/Intro";
import picture from "../../assets/image/portrait.png";

const HomePage = () => {
  return (
    <section className="home">
      {/* <div className="home__wrapper"> */}

      <div className="home__img-container">
        <img className="home__img-portrait" src={picture} alt="profile"></img>
      </div>
      <Intro />
      {/* </div> */}
    </section>
  );
};

export default HomePage;
