import { Link } from "react-router-dom";
import aircanada from "../../assets/image/aircanada.png";
import fundfinder from "../../assets/image/fundfinder.png";
import legallink from "../../assets/image/legallink.png";
import reactLogo from "../../assets/icons/react-icon.png";
import sass from "../../assets/icons/sass-icon.png";
import node from "../../assets/icons/node-icon.png";
import express from "../../assets/icons/express-icon.png";
import js from "../../assets/icons/js-icon.png";
import auth from "../../assets/icons/oAuth.webp";

const Project = () => {
  const pastProjects = [
    {
      id: 1,
      name: "LegalLink",
      image: legallink,
      description:
        "Legal Link is a mobile application that serves as a bridge between law firms and their clients, facilitating effective communication of updates, information and prompt responses to questions and concerns.",
      github_link: "https://github.com/Kimberlynolsin/LegalLink-Client",
      tech_stack: [
        { id: 1, icons: js },
        { id: 2, icons: reactLogo },
        { id: 4, icons: sass },
        { id: 5, icons: node },
        { id: 6, icons: express },
      ],
    },
    {
      id: 2,
      name: "Air-Code-Ada",
      image: aircanada,
      description:
      "As part of our comprehensive approach to optimizing Aeroplan's website, we leveraged our expertise in user experience design to tailor the dashboard and its features in a bespoke manner, with the goal of fostering increased member engagement and providing a highly personalized and seamless digital experience.",
      github_link: "https://github.com/jessemcc/air-code-ada",
      tech_stack: [
        { id: 1, icons: js },
        { id: 2, icons: reactLogo },
        { id: 4, icons: sass },
      ],
    },
    {
      id: 3,
      name: "FundFinder",
      image: fundfinder,
      description:
        "Fund Finder provides a database of grants, scholarships, and bursaries available to individuals in need. The app aims to make it easier for people to access funding that can help them pursue their educational and professional aspirations.",
      github_link: "https://github.com/Jorfishdan/FundFinder-client",
      tech_stack: [
        { id: 1, icons: js },
        { id: 2, icons: reactLogo },
        { id: 4, icons: sass },
        { id: 5, icons: node },
        { id: 6, icons: express },
        { id: 7, icons: auth },
      ],
    },
  ];

  const techStackIcons1 = pastProjects[0].tech_stack.map((tech) => {
    return (
      <div key={tech.id}>
        <img
          src={tech.icons}
          className="project__tech--icon"
          alt="tech stack icons"
        ></img>
      </div>
    );
  });

  const techStackIcons2 = pastProjects[1].tech_stack.map((tech) => {
    return (
      <div key={tech.id}>
        <img
          src={tech.icons}
          className="project__tech--icon"
          alt="tech stack icons"
        ></img>
      </div>
    );
  });

  const techStackIcons3 = pastProjects[2].tech_stack.map((tech) => {
    return (
      <div key={tech.id}>
        <img
          src={tech.icons}
          className="project__tech--icon"
          alt="tech stack icons"
        ></img>
      </div>
    );
  });

  return (
    <section className="project">
      <h1 className="project__title">PROJECTS</h1>
      <div className="project__mainwrap">
        <div className="project__container" key={pastProjects[0].id}>
          <div className="project__wrapper">
            <div className="project__box">
              <Link to="https://github.com/Kimberlynolsin/LegalLink-Client" target='_blank'><h2 className="project__subtitle">{pastProjects[0].name}</h2></Link>
              <div className="project__image">
                <img
                  className="project__image--individual"
                  src={pastProjects[0].image}
                  alt="legal link"
                ></img>
              </div>
            </div>
          </div>
          <div>
            <p className="project__description">
              {pastProjects[0].description}
            </p>
            <div className="project__tech">{techStackIcons1}</div>
            <Link
              to={pastProjects[0].github_link}
              className="project__link"
              target="_blank"
            >
              <p className="project__github">Github</p>
            </Link>
          </div>
        </div>

        <div className="project__container" key={pastProjects[1].id}>
          <div className="project__wrapper">
            <div className="project__box">
              <Link to="https://github.com/jessemcc/air-code-ada" target='_blank'><h2 className="project__subtitle">{pastProjects[1].name}</h2></Link>
              <div className="project__image">
                <img
                  className="project__image--individual"
                  src={pastProjects[1].image}
                  alt="air-code-ada"
                ></img>
              </div>
            </div>
          </div>
          <div>
            <p className="project__description">
              {pastProjects[1].description}
            </p>
            <div className="project__tech">{techStackIcons2}</div>

            <Link
              to={pastProjects[1].github_link}
              className="project__link"
              target="_blank"
            >
              <p className="project__github">Github</p>
            </Link>
          </div>
        </div>

        <div className="project__container" key={pastProjects[2].id}>
          <div className="project__wrapper">
            <div className="project__box">
              <Link to="https://github.com/Jorfishdan/FundFinder-client" target='_blank'><h2 className="project__subtitle">{pastProjects[2].name}</h2></Link>
              <div className="project__image">
                <img
                  className="project__image--individual"
                  src={pastProjects[2].image}
                  alt="fund finder"
                ></img>
              </div>
            </div>
          </div>
          <div>
            <p className="project__description">
              {pastProjects[2].description}
            </p>
            <div className="project__tech">{techStackIcons3}</div>

            <Link
              to={pastProjects[2].github_link}
              className="project__link"
              target="_blank"
            >
              <p className="project__github">Github</p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Project;
