import { Link } from "react-router-dom";
import logo from "../../assets/logo/1.png";
const Header = () => {
  return (
    <header className="header">
      <Link to="/">
        <img src={logo} className="header-container__logo" alt="logo"></img>
      </Link>
      <div className="header__menu">
      <nav className="header__nav">
        <ul className="header__menu__list">
          <li className="header__menu__item">
            <Link to="/about" className="header__menu__link">
              ABOUT
            </Link>
          </li>
          <li className="header__menu__item">
            <Link to="/projects" className="header__menu__link">
              PROJECTS
            </Link>
          </li>
          <li className="header__menu__item">
            <Link to="/contacts" className="header__menu__link">
              CONTACT
            </Link>
          </li>
        </ul>
      </nav>
      </div>
    </header>
  );
};

export default Header;
