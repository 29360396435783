import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import github from '../../assets/icons/github.png'
import linkedin from '../../assets/icons/linkedin.png'
import copy from '../../assets/icons/copyright.png'
const Contact = () => {
  const formRef = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const form = formRef.current;

    const name = form.name.value;
    const email = form.email.value;
    const message = form.message.value;

    if (!name || !email || !message) {
      toast.error("Please fill in all fields");
      return;
    }

    emailjs
      .sendForm(
        "service_l1nvfq8",
        "template_48ya754",
        formRef.current,
        "AuHb_TIxmhhfEW8ru"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Email sent!");
          form.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="contact">
      <div className="contact__box">
        <h1 className="contact__title">CONTACT</h1>
      </div>
      <form className="contact__form" ref={formRef} onSubmit={sendEmail}>
        <label>Name:</label>
        <input type="text" name="name" />
        <label>Email:</label>
        <input type="email" name="email" />
        <label>Message:</label>
        <textarea name="message" />
        <input className="contact__send" type="submit" value="Send" />
      </form>
      <div className="contact__getInTouch">
        <div className="contact__getInTouch--wrapper">
          <Link
            className="contact__getInTouch--github"
            to="https://github.com/Kimberlynolsin"
            target="_blank"
          >
            <img
              className="contact__getInTouch__img"
              src={github}
              alt="gmail github"
            ></img>
          </Link>
          <Link
            className="contact__getInTouch--linkedin"
            to="https://www.linkedin.com/in/kimberly-nolsin/"
            target="_blank"
          >
            <img
              className="contact__getInTouch__img"
              src={linkedin}
              alt="linkedin icon"
            ></img>
          </Link>
        </div>
        <div className="contact__getInTouch__copyright">
          <img
            className="contact__getInTouch__copyright--img"
            src={copy}
            alt="copyright icon"
          ></img>
          <p className="contact__getInTouch__copyright--text">
            2023 Kimberly Nolsin
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
