const Intro = () => {
  return (
    <section className="intro">
      <div className="intro__box">
        <h1 className="intro__title">
          HELLO, MY NAME IS<span className="intro__highlight">KIMBERLY</span>
          AND I'M A<span className="intro__highlight">FRONT-END ENGINEER</span>.
        </h1>
        <p className="intro__sentence">
          I possess a fervent drive for developing groundbreaking solutions to
          intricate challenges. When I am not actively engaged in website
          design, I can often be found immersed in the world of gaming.
        </p>
      </div>
    </section>
  );
};

export default Intro;
