import { Link } from "react-router-dom";
import React from "../../assets/icons/react-icon.png";
import pdf from "../../assets/pdf/Resume.pdf";
const About = () => {
  
  return (
    <section className="about">
      <h1 className="about__title">ABOUT ME</h1>
      <div className="about__wrapper">
        <div className="about-container">
        </div>
        <div>
          <p className="about__text">
            My name is Kimberly Nolsin and I am currently residing in Canada. I
            have a deep passion for programming, which transcends beyond just a
            mere job or hobby. 
            <br/>
            <br/>
             From a young age, I have always had an inherent
            skill for problem-solving, and programming has provided me with the
            ideal avenue to exercise this ability. Despite the stereotype
            associated with computer enthusiasts, I am a well-rounded
            individual, and my passion for programming is not limited to staring
            at lines of code. My true motivation lies in the satisfaction of
            utilizing my programming skills to solve complex problems. As a
            result, I consider myself not just a programmer, but a coding wizard
            with a tenacious drive to tackle any challenge thrown my way.
            <br/> 
            <br/> 
            If you
            are seeking an individual who embodies these qualities and possesses
            an unwavering passion for programming, then I am the ideal candidate
            for your team.
          </p>
          <Link
            className="about__resume about__resume--tablet"
            to={pdf}
            target="_blank"
          >
            RESUME
          </Link>
        </div>
      </div>
      <Link className="about__resume" to={pdf} target="_blank">
        RESUME
      </Link>
    </section>
  );
};

export default About;
